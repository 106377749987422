body {
  margin: 0;
  padding: 0;
  /* background-color: #1d2634 !important; */
  /* color: #9e9ea4 !important; */
  font-family: "Montserrat", sans-serif;
}
.invoice-grid-container {
  display: grid !important;
  grid-template-columns: 230px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar header header header"
    "sidebar main main main";
  height: 93vh;
}
.client-grid-container {
  display: grid !important;
  grid-template-columns: 220px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar header header header"
    "sidebar main main main";
  height: 100vh;
}
.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon,
.icon_header {
  margin-right: 5px;
}
.close_icon {
  color: red;
  cursor: pointer;
}

/* Header */

.invoice-header {
  grid-area: header;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  /* background-color: #1d2634 !important; */
  /* color: #9e9ea4 !important; */
  box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
}
.menu-icon {
  display: none;
}

.headerdropdown::after {
  display: none !important;
}

/* End Header */

/* Sidebar */

#invoice-sidebar {
  grid-area: sidebar;
  height: 100%;
  background-color: #222;
  color: #9e9ea4 !important;
  overflow-y: hidden;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
#invoice-sidebar:hover {
  overflow-y: auto; /* Enable scrolling on hover */
}
#invoice-sidebar::-webkit-scrollbar {
  width: 8px;
}
#invoice-sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2); /* Custom scrollbar color */
  border-radius: 10px; /* Rounded scrollbar corners */
}
.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 0 30px;
  margin-bottom: 10px;
}
.sidebar-title > span {
  display: none;
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}
.sidebar-list-item {
  padding: 5px 20px 5px 20px;
  font-size: 18px;
  position: relative;
}
.sidebar-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-list-item > a {
  text-decoration: none;
  color: #9e9ea4;
}
.sidebar-heading > a {
  text-decoration: none;
  color: #9e9ea4;
}
.sidebar-list-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #9e9ea4;
  cursor: pointer;
}
/* .sidebar-list-item .dropdown-content li:hover > a{
    color:rgba(255,255,255, 0.7) !important;
  } */
.sidebar-list-item > a:hover {
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
.sidebar-heading > a:hover {
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
  z-index: 12 !important;
}

.active-link {
  color: #ffffff !important;
}
/* End Sidebar */

/* Main */

.main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 10px 20px;
  background-color: #ffffff;
  color: black;

  /* color: rgba(255, 255, 255, 0.95); */
}
.main-title {
  display: flex;
  justify-content: space-between;
}

/* End Main */

/* Medium <= 992px */

@media screen and (max-width: 992px) {
  .invoice-grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "header"
      "main";
  }
  #invoice-sidebar {
    display: none;
  }
  .menu-icon {
    display: inline;
  }
  .sidebar-title {
    margin-bottom: 20px;
  }
  .sidebar-title > span {
    display: inline;
  }
  .hiddentext {
    display: none;
  }
  .client-grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "header"
      "main";
  }
}

@media screen and (max-width: 576px) {
  .icon {
    font-size: 16px !important;
  }
}

/* dialog box */
@media (max-width: 576px) {
  .dialogboxdiv {
    flex-direction: column !important;
    gap: 10px;
  }
}

/* related to table in cients,leads,doucments */

.table-container {
  height: 50vh;
}

.table-invcontainer {
  height: 70vh;
  /* overflow: hidden; */
}

.tablediv {
  height: 100%;
  overflow-y: auto;
}

.tablediv thead {
  position: sticky;
  top: 0;
}

.employeeul {
  height: 200px;
  overflow-y: scroll;
  width: 100%;
}

.clientli {
  height: 200px;
  overflow-y: scroll;
}

.clientul {
  height: 240px;
}

.btndiv{
  display:flex;
  justify-content: center;
  align-items: center;
}


