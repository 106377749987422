

  .loader-container {
    width: 100vw;
    height: 100vh;
    background-color:  #cad801; 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  canvas {
    width: 100%;
    height: 100%;
  }
  

 
  
  
  